// src/components/FireNav.js
import React, { useMemo } from "react";
import { alpha, styled } from "@mui/material/styles";
import { useTheme } from "@mui/material/styles";
import PropTypes from "prop-types";

const FireNav = ({ children, variant = "primary" }) => {
  const theme = useTheme();

  const styles = useMemo(() => {
    const variants = variant.split(" ");

    const defaultStyles = {
      color: theme.palette.text.primary,
      selectedBgColor: theme.palette.action.selected,
      hoverBgColor: theme.palette.action.hover,
      hoverTextColor: theme.palette.action.hover,
      contrastText: theme.palette.text.primary,
      height: "",
      fontSize: "",
      iconMargin: "12px",
      iconSize: "24px",
      lineHeight: "1.5",
      fontWeight: "",
    };

    const variantStyles = {
      primary: {
        color: theme.palette.primary.main,
        selectedBgColor: theme.palette.primary.main,
        hoverBgColor: theme.palette.primary.light,
        contrastText: theme.palette.primary.contrastText,
       
      },
      secondary: {
        color: theme.palette.secondary.main,
        selectedBgColor: alpha(theme.palette.secondary.main, 0.25),
        hoverBgColor: theme.palette.secondary.light,
        hoverTextColor: "white",
        contrastText: theme.palette.secondary.main,
      },
      dense: {
        height: "40px",
        fontSize: "0.95rem",
        iconMargin: "8px",
        iconSize: "0px",
        lineHeight: "1",
        fontWeight: "500",
 
      },
    };

    return variants.reduce((acc, v) => {
      return { ...acc, ...variantStyles[v] };
    }, defaultStyles);
  }, [theme, variant]);

  return <StyledDiv styles={styles}>{children}</StyledDiv>;
};

const StyledDiv = styled("div")(({ styles }) => ({
  "& .MuiListItem-root, & .MuiListItemButton-root": {
    color: styles.color,
    backgroundColor: "transparent",
    borderRadius: "8px",
    margin: "0 0",
    padding: "12px 8px",
    maxHeight: styles.height,

    "& .MuiTypography-root": {
      color: styles.color,
      fontSize: styles.fontSize,
      fontWeight: styles.fontWeight,
      lineHeight: styles.lineHeight,
      paddingLeft: styles.iconMargin,
  
    },
    "& .MuiListItemText-root": {
      paddingLeft: 0,
      flexGrow: 1,
    },

    "& .MuiListItemIcon-root": {
      color: "inherit",
      minWidth: "0",
    },
    "& .MuiSvgIcon-root": {
      fontSize: styles.iconSize,
    },
    "&.Mui-selected": {
      backgroundColor: styles.selectedBgColor,
      color: styles.contrastText,
      "& .MuiTypography-root": {
        color: styles.contrastText,
      },
      "& .MuiListItemIcon-root": {
        color: styles.contrastText,
      },
      "&:hover": {
        backgroundColor: styles.selectedBgColor,
        color: styles.contrastText,
      },
    },
  },
}));

FireNav.propTypes = {
  children: PropTypes.node.isRequired,
  variant: PropTypes.string, // Allow multiple variants as a space-separated string
};

export default FireNav;
