import React, { useEffect, useState } from "react";
import {
  Typography,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
  TableBody,
  TableContainer,
  Table,
  Paper,
} from "@mui/material";
import { Button } from "@mui/material";
import { useThemeToggle } from "../components/customThemeProvider";
import { useNavigate } from "react-router-dom";
import { getEvents } from "../database/events";
import logo from "../assets/logo.png";

export default function Home() {
  const navigate = useNavigate();
  const [selectedEvent, setSelectedEvent] = useState("");
  const [events, setEvents] = useState([]);
  const toggleTheme = useThemeToggle();
  const [toggleUi, setToggleUi] = useState(true);

  const handleChange = (event) => {
    setSelectedEvent(event.target.value);
  };

  useEffect(() => {
    const get = async () => {
      let events = await getEvents();

      const sortedEvents = events.sort((b, a) => {
        if (a.createdAt.seconds !== b.createdAt.seconds) {
          return a.createdAt.seconds - b.createdAt.seconds;
        }
        return a.createdAt.nanoseconds - b.createdAt.nanoseconds;
      });

      setEvents(sortedEvents);
    };
    get();
  }, []);

  const inicio = (
    <Box
      sx={{
        marginTop: { lg: "25vh", md: "20vh", xs: "10vh" },
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        width: "100%",
        height: "100%",
        padding: { xs: 10 },
      }}
    >
      <Typography variant="h2" sx={{ marginBottom: 4 }}>
        Puntuaciones
      </Typography>

      <Typography variant="h6" sx={{ marginBottom: 2 }}>
        Selecciona un evento para ver los puntajes
      </Typography>

      <FormControl sx={{ minWidth: 280, marginBottom: 3 }} variant="outlined">
        <InputLabel id="event-select-label">Eventos</InputLabel>
        <Select
          labelId="event-select-label"
          id="event-select"
          value={selectedEvent}
          onChange={handleChange}
          label="Eventos"
        >
          <MenuItem value="">
            <em>Seleccione un evento</em>
          </MenuItem>
          {events.map((e, i) => (
            <MenuItem key={e.code} value={i}>
              {e.title}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <Button
        variant="contained"
        color="primary"
        sx={{ padding: "10px 70px", marginBottom: 4, fontSize: "20px" }}
        onClick={() => {
          setToggleUi(false);
          console.log(events[selectedEvent]);
        }}
        disabled={!events[selectedEvent]} // Disable button if no event is selected
      >
        Ver Resultados
      </Button>
    </Box>
  );

  const tabla = (
    <Box
      sx={{
        overflow: "auto",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        width: "100%",
        height: "100%",
        marginTop: { xs: "30px" },
        padding: { lg: 10, md: 5, xs: 3 },
      }}
    >
      <br />

      {/* Check if selectedEvent is valid */}
      {selectedEvent !== "" && events[selectedEvent] && (
        <>
          <Typography
            sx={{
              margin: 0,
              fontWeight: "700",
              fontSize: "40px",
            }}
          >
            {events[selectedEvent].title}
          </Typography>
          <img
            src={logo}
            style={{
              height: 250,
              width: 250,
            }}
          />

          <br />
          <TableContainer sx={{ overflow: "auto" }}>
            <Table sx={{ maxWidth: "100%" }} size={"medium"} align="center">
              <TableHead>
                <TableRow>
                  <TableCell align="left">
                    <TableSortLabel
                      sx={{
                        fontWeight: "700",
                        fontSize: "20px",
                      }}
                    >
                      Nombre
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="left">
                    <TableSortLabel
                      sx={{
                        fontWeight: "700",
                        fontSize: "20px",
                      }}
                    >
                      Club
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="left">
                    <TableSortLabel
                      sx={{
                        fontWeight: "700",
                        fontSize: "20px",
                      }}
                    >
                      Franja
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="right">
                    <TableSortLabel
                      sx={{
                        fontWeight: "700",
                        fontSize: "20px",
                      }}
                    >
                      Nivel
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="right">
                    <TableSortLabel
                      sx={{
                        fontWeight: "700",
                        fontSize: "20px",
                      }}
                    >
                      Salto
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="right">
                    <TableSortLabel
                      sx={{
                        fontWeight: "700",
                        fontSize: "20px",
                      }}
                    >
                      Barra
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="right">
                    <TableSortLabel
                      sx={{
                        fontWeight: "700",
                        fontSize: "20px",
                      }}
                    >
                      Viga
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="right">
                    <TableSortLabel
                      sx={{
                        fontWeight: "700",
                        fontSize: "20px",
                      }}
                    >
                      Suelo
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="right">
                    <TableSortLabel
                      sx={{
                        fontWeight: "700",
                        fontSize: "20px",
                      }}
                    >
                      Acumulado
                    </TableSortLabel>
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {events[selectedEvent].clubs.map((club, clubIndex) =>
                  club.students.map((student, studentIndex) => (
                    <TableRow
                      key={`${clubIndex}-${studentIndex}`}
                      sx={{ height: "60px" }}
                    >
                      <TableCell
                        component="th"
                        scope="row"
                        padding="none"
                        align="left"
                        sx={{
                          fontSize: "15px",
                        }}
                      >
                        {student.name}
                      </TableCell>
                      <TableCell
                        align="left"
                        padding="none"
                        sx={{
                          fontSize: "15px",
                        }}
                      >
                        {club.clubName}
                      </TableCell>
                      <TableCell
                        align="left"
                        padding="none"
                        sx={{
                          fontSize: "15px",
                        }}
                      >
                        {student.timeSlot || "No asignado"}
                      </TableCell>
                      <TableCell
                        align="right"
                        padding="none"
                        sx={{
                          fontSize: "15px",
                        }}
                      >
                        {student.level}
                      </TableCell>
                      <TableCell
                        align="right"
                        padding="none"
                        sx={{
                          fontSize: "15px",
                        }}
                      >
                        {student.saltoScore || "0"}
                      </TableCell>
                      <TableCell
                        align="right"
                        padding="none"
                        sx={{
                          fontSize: "15px",
                        }}
                      >
                        {student.barraScore || "0"}
                      </TableCell>
                      <TableCell
                        align="right"
                        padding="none"
                        sx={{
                          fontSize: "15px",
                        }}
                      >
                        {student.vigaScore || "0"}
                      </TableCell>
                      <TableCell
                        align="right"
                        padding="none"
                        sx={{
                          fontSize: "15px",
                        }}
                      >
                        {student.sueloScore || "0"}
                      </TableCell>
                      <TableCell
                        align="right"
                        padding="none"
                        sx={{
                          fontSize: "15px",
                        }}
                      >
                        {[
                          student.saltoScore,
                          student.barraScore,
                          student.vigaScore,
                          student.sueloScore,
                        ].reduce(
                          (acc, score) => acc + (parseFloat(score) || 0),
                          0
                        )}
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </Box>
  );

  return (
    <Box
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {!toggleUi && (
        <Button
          onClick={() => {
            setToggleUi(true);
          }}
          variant="contained"
          color="primary"
          sx={{
            position: "absolute",
            top: 16,
            left: 16,
            zIndex: 1, // Asegura que el botón esté por encima de otros elementos
          }}
        >
          Volver atras
        </Button>
      )}

      <Button
        onClick={toggleTheme}
        variant="contained"
        color="secondary"
        sx={{
          position: "absolute",
          top: 16,
          right: 150,
          zIndex: 1, // Asegura que el botón esté por encima de otros elementos
        }}
      >
        Cambiar Tema
      </Button>
      <Button
        onClick={() => {
          navigate("/login");
        }}
        variant="contained"
        color="primary"
        sx={{
          position: "absolute",
          top: 16,
          right: 16,
          zIndex: 1, // Asegura que el botón esté por encima de otros elementos
        }}
      >
        Iniciar sesión
      </Button>
      {toggleUi ? inicio : tabla}
    </Box>
  );
}
