import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { ThemeProvider, createTheme } from "@mui/material";
import React, { useState } from "react";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../database/config";
import { useNavigate } from "react-router-dom";
import { verifyCodeLogin } from "../database/users";
import loadingSVG from "../assets/loading.svg";
import Swal from "sweetalert2";
import InputAdornment from "@mui/material/InputAdornment";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { useDispatch } from "react-redux";
import { login } from "../actions/auth_actions";
import logo from "../assets/logo.png";
import { useThemeToggle } from "../components/customThemeProvider";
import { AlternateEmailOutlined, VpnKeyOutlined } from "@mui/icons-material";

export default function Login() {
  const dispatch = useDispatch();
  const toggleTheme = useThemeToggle();
  const handleLogin = (userData, userId) => {
    dispatch(login(userData, userId));
  };

  // const [openError, setOpenError] = useState(false);
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  // const [error, setError] = useState("");
  const [password, setPassword] = useState("");
  const [code, setCode] = useState("");
  const [toggleLogin, setToggleLogin] = useState(false);
  // const onLogin = async (e) => {
  //   Swal.fire({
  //     imageUrl: loadingSVG,
  //     imageWidth: 300,
  //     imageHeight: 300,
  //     title: "Cargando...",
  //     showConfirmButton: false,
  //     allowOutsideClick: false,
  //     allowEscapeKey: false,
  //   });
  //   e.preventDefault();
  //   // console.log(email, password);
  //   // setError("");
  //   try {
  //     // console.log("auth", auth, email, password);
  //     const userCredential = await signInWithEmailAndPassword(
  //       auth,
  //       email.trim(),
  //       password
  //     );
  //     console.log("userCredential", userCredential);
  //     const user = userCredential.user;
  //     console.log("user", user);
  //     // if (user.emailVerified) {
  //     // var resDoctor = await getDoctorById(user.uid);
  //     const resUser = await getUserById(user.uid);
  //     // console.log("resUser", resUser);

  //     handleLogin(resUser, user.uid);
  //     if (resUser.role === "doctor") {
  //       if (resUser.firstTime === true) {
  //         navigate("/completeProfile");
  //         Swal.close();
  //       } else {
  //         navigate("/home");
  //         Swal.close();
  //       }
  //     } else if (resUser.role === "medicalCenter") {
  //       // console.log("resUser", resUser);
  //       if (resUser.firstTime === true) {
  //         navigate("/completeCenterMed");
  //         Swal.close();
  //       } else {
  //         navigate("/");
  //         Swal.close();
  //       }
  //     }
  //     if (resUser.role === "secretary") {
  //       navigate("/home");
  //       Swal.close();
  //     } else if (resUser.role === "admin") {
  //       navigate("/homeAdmin");
  //       Swal.close();
  //     }
  //     // } else {
  //     // setOpenError(true);
  //     // Swal.close();
  //     // Swal.fire({
  //     //   icon: 'error',
  //     //   title: 'Oops...',
  //     //   text: "Debes verificar tu correo para poder ingresar. Si no lo encuentras revisa tu bandeja de spam."
  //     //   ,
  //     // })
  //     // }
  //   } catch (error) {
  //     if (error.code === "auth/wrong-password") {
  //       Swal.close();
  //       Swal.fire({
  //         icon: "error",
  //         title: "Oops...",
  //         text: "Contraseña incorrecta. Verifica tus credenciales.",
  //       });
  //     } else if (error.code === "auth/too-many-requests") {
  //       Swal.close();
  //       Swal.fire({
  //         icon: "error",
  //         title: "Oops...",
  //         text: "Demasiados intentos fallidos. Inténtalo después.",
  //       });
  //     } else {
  //       Swal.close();
  //       Swal.fire({
  //         icon: "error",
  //         title: "Oops...",
  //         text: "Correo no encontrado. Verifica tus datos.",
  //       });
  //     }
  //     // setError(error)
  //   }
  // };

  const digitadorLogin = async (code) => {
    Swal.fire({
      imageUrl: loadingSVG,
      imageWidth: 200,
      imageHeight: 200,
      title: "Cargando...",
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
    });
    let a = await verifyCodeLogin(code);
    localStorage.setItem("EventToType", JSON.stringify(a));

    if (a.length > 0) {
      navigate("/typist");
      Swal.close();
    } else {
      Swal.fire({
        icon: "warning",
        title: "Upss..",
        text: "No se encontro ningun evento asociado a ese codigo",
      });
    }
  };
  const onLogin = async (e) => {
    Swal.fire({
      imageUrl: loadingSVG,
      imageWidth: 200,
      imageHeight: 200,
      title: "Cargando...",
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
      timer: 2000,
    }).then((e) => {
      if (e.isDismissed || e.dismiss === "timer") {
        navigate("/inicio");
      }
      console.log(e);
    });
    e.preventDefault();
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <Button
        onClick={toggleTheme}
        variant="contained"
        color="secondary"
        sx={{
          position: "absolute",
          top: 16,
          right: 16,
          zIndex: 1, // Asegura que el botón esté por encima de otros elementos
        }}
      >
        Cambiar Tema
      </Button>
      <Button
        onClick={() => {
          setToggleLogin(!toggleLogin);
        }}
        variant="contained"
        color="primary"
        sx={{
          position: "absolute",
          top: 16,
          left: 16,
          zIndex: 1, // Asegura que el botón esté por encima de otros elementos
        }}
      >
        {toggleLogin ? "Iniciar como usuario" : "Iniciar como digitador"}
      </Button>
      <Grid container justifyContent="center">
        <Grid item xs={11} md={4}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              component="img"
              src={logo}
              sx={{ width: "200px", height: "200px", marginTop: 1 }}
            />
            <Typography component="h2" variant="h5" sx={{ color: "primary" }}>
              Ingresa a gymnasticscores
            </Typography>
          </Box>
          <Box noValidate sx={{ mt: 1, width: "100%" }}>
            {toggleLogin ? (
              <TextField
                sx={{ mb: 2 }}
                margin="normal"
                required
                fullWidth
                placeholder="Codigo"
                autoFocus
                onChange={(e) => setCode(e.target.value)}
                variant="filled"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <VpnKeyOutlined />
                    </InputAdornment>
                  ),
                  style: {
                    padding: "3%",
                  },
                }}
                inputProps={{
                  style: {
                    height: "90%",
                  },
                }}
              />
            ) : (
              <>
                <TextField
                  sx={{ mb: 2 }}
                  margin="normal"
                  required
                  fullWidth
                  placeholder="Correo"
                  autoComplete="email"
                  autoFocus
                  onChange={(e) => setEmail(e.target.value)}
                  variant="filled"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AlternateEmailOutlined />
                      </InputAdornment>
                    ),
                    style: {
                      padding: "3%",
                    },
                  }}
                  inputProps={{
                    style: {
                      height: "90%",
                    },
                  }}
                />
                <TextField
                  sx={{ mb: 2 }}
                  margin="normal"
                  required
                  fullWidth
                  placeholder="Contraseña"
                  type="text"
                  autoComplete="current-password"
                  onChange={(e) => setPassword(e.target.value)}
                  variant="filled"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LockOutlinedIcon />
                      </InputAdornment>
                    ),
                    style: {
                      padding: "3%",
                    },
                  }}
                  inputProps={{
                    style: {
                      height: "90%",
                    },
                  }}
                />
              </>
            )}

            <Button
              fullWidth
              variant="contained"
              color="primary"
              onClick={() => {
                if (toggleLogin) {
                  digitadorLogin(code);
                } else {
                  onLogin();
                }
              }}
              sx={{
                mt: 3,
                mb: 2,
                height: "48px",
                borderRadius: "25px",
              }}
            >
              {toggleLogin ? "Ingresar" : "Iniciar sesión "}
            </Button>
            <Grid
              container
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Grid item>
                <Button
                  variant="outlined"
                  sx={{
                    mt: 3,
                    mb: 2,
                    height: "48px",
                    borderRadius: "25px",
                    color: "gray",
                  }}
                  onClick={() => {
                    navigate("/");
                  }}
                >
                  Volver atras
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
