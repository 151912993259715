import { useEffect, useMemo, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  TextField,
  IconButton,
  Typography,
  Chip,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
} from "@mui/material";
import { useSelector } from "react-redux";
import {
  AddCircleOutline,
  Delete,
  ExpandLess,
  ExpandMore,
} from "@mui/icons-material";
import { createEvent } from "../database/events";
import Swal from "sweetalert2";
import loadingSVG from "../assets/loading.svg";

export default function CreateEvent() {
  const dataDoctor = useSelector((state) => state.auth.userData);
  const [newLevel, setNewLevel] = useState("");
  const [newTimeSlot, setNewTimeSlot] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [expandedClubs, setExpandedClubs] = useState({});
  const [eventData, setEventData] = useState({
    title: "",
    date: "",
    levels: [],
    timeSlots: [],
    clubs: [
      {
        clubName: "",
        representative: { name: "", rut: "", email: "" },
        students: [
          {
            name: "",
            rut: "",
            level: "",
            salto: "",
            barra: "",
            viga: "",
            suelo: "",
            aa: "",
          },
        ],
      },
    ],
  });
  const toggleExpandClub = (clubIndex) => {
    setExpandedClubs((prev) => ({
      ...prev,
      [clubIndex]: !prev[clubIndex], // Alterna el estado expandido/colapsado
    }));
  };
  const handleInputChange = (e, clubIndex, studentIndex, field, subField) => {
    const updatedClubs = [...eventData.clubs];
    if (subField) {
      if (studentIndex !== null) {
        updatedClubs[clubIndex].students[studentIndex][subField] =
          e.target.value;
      } else {
        updatedClubs[clubIndex][field][subField] = e.target.value;
      }
    } else {
      updatedClubs[clubIndex][field] = e.target.value;
    }
    setEventData({ ...eventData, clubs: updatedClubs });
  };

  const addClub = () => {
    setEventData({
      ...eventData,
      clubs: [
        ...eventData.clubs,
        {
          clubName: "",
          representative: { name: "", rut: "", email: "" },
          students: [
            {
              name: "",
              rut: "",
              level: "",
              salto: "",
              barra: "",
              viga: "",
              suelo: "",
              aa: "",
            },
          ],
        },
      ],
    });
  };

  const removeClub = (index) => {
    const updatedClubs = eventData.clubs.filter((_, i) => i !== index);
    setEventData({ ...eventData, clubs: updatedClubs });
  };

  const addStudents = (clubIndex, numberOfRows = 5) => {
    const updatedClubs = [...eventData.clubs];
    for (let i = 0; i < numberOfRows; i++) {
      updatedClubs[clubIndex].students.push({
        name: "",
        rut: "",
        level: "",
        barraScore: "",
        sueloScore: "",
      });
    }
    setEventData({ ...eventData, clubs: updatedClubs });
  };

  const removeStudent = (clubIndex, studentIndex) => {
    const updatedClubs = [...eventData.clubs];
    updatedClubs[clubIndex].students = updatedClubs[clubIndex].students.filter(
      (_, i) => i !== studentIndex
    );
    setEventData({ ...eventData, clubs: updatedClubs });
  };

  const handleSave = () => {
    setOpenDialog(true);
  };

  const handleDialogClose = (confirm) => {
    if (confirm) {
      console.log("Datos del evento guardados:", eventData);
    }
    setOpenDialog(false);
  };

  const addLevel = () => {
    if (newLevel.trim() !== "") {
      setEventData({ ...eventData, levels: [...eventData.levels, newLevel] });
      setNewLevel(""); // Limpiar el campo
    }
  };

  const removeLevel = (index) => {
    const updatedLevels = eventData.levels.filter((_, i) => i !== index);
    setEventData({ ...eventData, levels: updatedLevels });
  };

  const addTimeSlot = () => {
    if (newTimeSlot.trim() !== "") {
      setEventData({
        ...eventData,
        timeSlots: [...eventData.timeSlots, newTimeSlot],
      });
      setNewTimeSlot(""); // Limpiar el campo
    }
  };

  const removeTimeSlot = (index) => {
    const updatedTimeSlots = eventData.timeSlots.filter((_, i) => i !== index);
    setEventData({ ...eventData, timeSlots: updatedTimeSlots });
  };

  const handleCreateEvent = async () => {
    Swal.fire({
      imageUrl: loadingSVG,
      imageWidth: 200,
      imageHeight: 200,
      title: "Cargando...",
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
    });
    const res = await createEvent(eventData);
    if (res) {
      Swal.fire({
        icon: "success",
        title: "Guardado",
        text: "El evento ha sido creado correctamente",
      });
    } else {
      Swal.fire({
        icon: "warning",
        title: "Upss..",
        text: "No se ha podido crear el evento",
      });
    }
  };

  return (
    <Paper
      elevation={0}
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        width: "100%",
        height: "100%",
        borderRadius: "20px",
        overflow: "auto",
        padding: "16px",
        alignItems: "start",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          width: "100%",
          alignItems: "start",
          padding: 2,
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h4">Crear Evento</Typography>
          </Grid>

          <Grid item xs={12}>
            <TextField
              label="Título del Evento"
              fullWidth
              value={eventData.title}
              onChange={(e) =>
                setEventData({ ...eventData, title: e.target.value })
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              label="Fecha del Evento"
              fullWidth
              type="date"
              InputLabelProps={{ shrink: true }}
              value={eventData.date}
              onChange={(e) =>
                setEventData({ ...eventData, date: e.target.value })
              }
            />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h6">Niveles de la Competencia</Typography>
            <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
              <TextField
                label="Nuevo Nivel"
                value={newLevel}
                onChange={(e) => setNewLevel(e.target.value)}
              />
              <Button variant="contained" onClick={addLevel}>
                Añadir Nivel
              </Button>
            </Box>
            <Box sx={{ marginTop: 2 }}>
              {eventData.levels.map((level, index) => (
                <Chip
                  key={index}
                  label={level}
                  onDelete={() => removeLevel(index)}
                  sx={{ marginRight: 1, marginBottom: 1 }}
                />
              ))}
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h6">Franjas</Typography>
            <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
              <TextField
                label="Nueva Franja"
                value={newTimeSlot}
                onChange={(e) => setNewTimeSlot(e.target.value)}
              />
              <Button variant="contained" onClick={addTimeSlot}>
                Añadir Franja
              </Button>
            </Box>
            <Box sx={{ marginTop: 2 }}>
              {eventData.timeSlots.map((slot, index) => (
                <Chip
                  key={index}
                  label={slot}
                  onDelete={() => removeTimeSlot(index)}
                  sx={{ marginRight: 1, marginBottom: 1 }}
                />
              ))}
            </Box>
            <br />
          </Grid>
          <Grid item xs={12} sx={{ marginLeft: "0.7vw" }}>
            {eventData.clubs.map((club, clubIndex) => (
              <Grid
                container
                alignItems={"center"}
                spacing={2}
                key={clubIndex}
                sx={{
                  border: "1px solid #ccc",
                  borderRadius: 2,
                  padding: 2,
                  marginBottom: 2,
                }}
              >
                <Grid item xs={12}>
                  <Grid
                    container
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Typography variant="h6">Club {clubIndex + 1}</Typography>
                    <IconButton onClick={() => toggleExpandClub(clubIndex)}>
                      {expandedClubs[clubIndex] ? (
                        <ExpandLess />
                      ) : (
                        <ExpandMore />
                      )}
                    </IconButton>
                  </Grid>
                </Grid>

                {/* Solo muestra el contenido si el club está expandido */}
                {expandedClubs[clubIndex] && (
                  <>
                    <Grid item xs={12}>
                      <TextField
                        label="Nombre del Club"
                        fullWidth
                        value={club.clubName}
                        onChange={(e) =>
                          handleInputChange(e, clubIndex, null, "clubName")
                        }
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="h6">Representante</Typography>
                      <TextField
                        label="Nombre del Representante"
                        fullWidth
                        value={club.representative.name}
                        onChange={(e) =>
                          handleInputChange(
                            e,
                            clubIndex,
                            null,
                            "representative",
                            "name"
                          )
                        }
                      />
                      <TextField
                        label="RUT del Representante"
                        fullWidth
                        value={club.representative.rut}
                        onChange={(e) =>
                          handleInputChange(
                            e,
                            clubIndex,
                            null,
                            "representative",
                            "rut"
                          )
                        }
                      />
                      <TextField
                        label="Correo del Representante"
                        fullWidth
                        value={club.representative.email}
                        onChange={(e) =>
                          handleInputChange(
                            e,
                            clubIndex,
                            null,
                            "representative",
                            "email"
                          )
                        }
                      />
                    </Grid>

                    {/* Sección para estudiantes en tabla */}
                    <Grid item xs={12}>
                      <Typography variant="h6">Estudiantes</Typography>
                      <TableContainer component={Paper}>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell>Nombre</TableCell>
                              <TableCell>RUT</TableCell>
                              <TableCell>Nivel</TableCell>
                              <TableCell>Franja</TableCell>
                              <TableCell>Acciones</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {club.students.map((student, studentIndex) => (
                              <TableRow key={studentIndex}>
                                <TableCell
                                  sx={{ minWidth: { xs: "40vw", sm: "10vw" } }}
                                >
                                  <TextField
                                    value={student.name}
                                    onChange={(e) =>
                                      handleInputChange(
                                        e,
                                        clubIndex,
                                        studentIndex,
                                        "students",
                                        "name"
                                      )
                                    }
                                    fullWidth
                                  />
                                </TableCell>

                                <TableCell
                                  sx={{ minWidth: { xs: "40vw", sm: "10vw" } }}
                                >
                                  <TextField
                                    value={student.rut}
                                    onChange={(e) =>
                                      handleInputChange(
                                        e,
                                        clubIndex,
                                        studentIndex,
                                        "students",
                                        "rut"
                                      )
                                    }
                                    fullWidth
                                  />
                                </TableCell>
                                <TableCell>
                                  <FormControl fullWidth>
                                    <InputLabel>Nivel</InputLabel>
                                    <Select
                                      value={student.level}
                                      onChange={(e) =>
                                        handleInputChange(
                                          e,
                                          clubIndex,
                                          studentIndex,
                                          "students",
                                          "level"
                                        )
                                      }
                                      label="Nivel"
                                    >
                                      {eventData.levels.map((level, index) => (
                                        <MenuItem key={index} value={level}>
                                          {level}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl>
                                </TableCell>
                                <TableCell>
                                  <FormControl fullWidth>
                                    <InputLabel>Franja</InputLabel>
                                    <Select
                                      value={student.timeSlot}
                                      onChange={(e) =>
                                        handleInputChange(
                                          e,
                                          clubIndex,
                                          studentIndex,
                                          "students",
                                          "timeSlot"
                                        )
                                      }
                                      label="Franja"
                                    >
                                      {eventData.timeSlots.map(
                                        (timeSlot, index) => (
                                          <MenuItem
                                            key={index}
                                            value={timeSlot}
                                          >
                                            {timeSlot}
                                          </MenuItem>
                                        )
                                      )}
                                    </Select>
                                  </FormControl>
                                </TableCell>

                                <TableCell>
                                  <IconButton
                                    onClick={() =>
                                      removeStudent(clubIndex, studentIndex)
                                    }
                                    color="secondary"
                                  >
                                    <Delete />
                                  </IconButton>
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>
                    <Grid
                      container
                      alignItems="flex-start"
                      spacing={1} // Reduce el espacio entre items para un diseño compacto
                      sx={{
                        borderRadius: 2,
                        padding: 2,
                        marginBottom: 2,
                      }}
                    >
                      <Grid item xs={12}>
                        <Box
                          display="flex"
                          gap={2}
                          alignItems="center"
                          justifyContent="flex-end"
                        >
                          <Button
                            startIcon={<AddCircleOutline />}
                            onClick={() => addStudents(clubIndex, 5)} // Añade 5 filas a la vez
                            variant="contained"
                            sx={{ marginTop: 2 }}
                          >
                            Añadir Filas
                          </Button>

                          <Button
                            startIcon={<Delete />}
                            onClick={() => removeClub(clubIndex)}
                            color="secondary"
                            variant="contained"
                            sx={{ marginTop: 2 }}
                          >
                            Eliminar Club
                          </Button>
                        </Box>
                      </Grid>
                    </Grid>
                  </>
                )}
              </Grid>
            ))}
          </Grid>

          <Grid
            container
            alignItems="flex-start"
            spacing={1}
            sx={{
              borderRadius: 2,
              padding: 2,
              marginBottom: 2,
            }}
          >
            <Grid item xs={12}>
              <Box display="flex" gap={2}>
                <Button
                  variant="contained"
                  onClick={addClub}
                  startIcon={<AddCircleOutline />}
                >
                  Añadir Club
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSave}
                >
                  Guardar Evento
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Grid>

        {/* Diálogo de confirmación */}
        <Dialog open={openDialog} onClose={() => handleDialogClose(false)}>
          <DialogTitle>Confirmar</DialogTitle>
          <DialogContent>
            <Typography>¿Deseas guardar los datos del evento?</Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => handleDialogClose(false)} color="secondary">
              Cancelar
            </Button>
            <Button
              onClick={() => {
                handleCreateEvent();
                handleDialogClose(true);
              }}
              color="primary"
            >
              Guardar
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </Paper>
  );
}
