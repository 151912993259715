import React, { useEffect, useState } from "react";

import {
  Drawer,
  ListItem,
  ListItemIcon,
  ListItemText,
  Container,
  Grid,
  Divider,
  Box,
  IconButton,
} from "@mui/material";
import {
  HomeTwoTone,
  CalendarMonthTwoTone,
  RoomServiceTwoTone,
  MessageTwoTone,
  VideocamTwoTone,
  SourceTwoTone,
  SettingsTwoTone,
  SupervisorAccount,
  LogoutRounded,
  KeyboardArrowLeftOutlined,
  KeyboardArrowRightOutlined,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  AddBoxOutlined,
} from "@mui/icons-material";

import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../actions/auth_actions";
import MenuIcon from "@mui/icons-material/Menu";
import Logo from "../assets/logo.png";
import FireNav from "./fire_nav";
import { signOut } from "firebase/auth";
import { auth } from "../database/config";

import AssessmentTwoToneIcon from "@mui/icons-material/AssessmentTwoTone";

const MyDrawer = ({
  mobileOpen,
  handleDrawerToggle,
  drawerWidth,
  handleDrawerToggleDesktop,
}) => {
  console.log("mobile", mobileOpen);
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [chatsUnread, setChatsUnread] = useState(0);
  const [openAtention, setOpenAtention] = useState(true);

  const [drawerOpen, setDrawerOpen] = useState(true);

  const myData = useSelector((state) => state.auth.userData);
  const isSelected = location.pathname === "/chatList";

  const handleToggleDrawer = () => {
    handleDrawerToggleDesktop();
    setDrawerOpen(!drawerOpen); // Alternar entre abierto y cerrado
    handleDrawerToggle();
  };

  const handleChangeView = (prop) => {
    navigate(prop);
  };

  const handleAtentionClick = () => {
    setOpenAtention(!openAtention);
  };

  const handleLogout = () => {
    try {
      dispatch(logout());
      signOut(auth)
        .then(() => {
          navigate("/login");
        })
        .catch((error) => {
          console.error("Error signing out:", error);
        });
    } catch (error) {
      console.log("Error signing out:", error);
    }
  };

  const drawerContent = (
    <Container
      disableGutters={true}
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        alignItems: "center",
        overflow: "auto",
        padding: "16px",
      }}
    >
      <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <a href="/home" style={{ width: "90%" }}>
          <img
            src={Logo}
            alt="Logo"
            width="100%"
            style={{ cursor: "pointer" }}
          />
        </a>
      </div>
      <br />
      <Grid container spacing={0} sx={{ width: "100%" }}>
        <Grid item xs={12}>
          <FireNav>
            <ListItem
              button
              selected={location.pathname === "/inicio"}
              onClick={() => handleChangeView("/inicio")}
            >
              <ListItemIcon>
                <AddBoxOutlined />
              </ListItemIcon>
              <ListItemText primary="Crear Evento" />
            </ListItem>
          </FireNav>
        </Grid>
        <Grid item xs={12}>
          <FireNav>
            <ListItem
              button
              selected={location.pathname === "/eventos"}
              onClick={() => handleChangeView("/eventos")}
            >
              <ListItemIcon>
                <CalendarMonthTwoTone />
              </ListItemIcon>
              <ListItemText primary="Eventos" />
            </ListItem>
          </FireNav>
        </Grid>
        {/* Agregar otros elementos del menú según sea necesario */}
      </Grid>
      <Grid container spacing={0} style={{ marginTop: "3vh" }}>
        <Grid item xs={12}>
          <Divider variant="middle" />
        </Grid>
        <Grid item xs={12}>
          <FireNav>
            <ListItem
              button
              selected={location.pathname === "/settings"}
              onClick={() => handleChangeView("/settings")}
            >
              <ListItemIcon>
                <SettingsTwoTone />
              </ListItemIcon>
              <ListItemText primary="Configuración" />
            </ListItem>
          </FireNav>
        </Grid>
        <Grid item xs={12}>
          <FireNav>
            <ListItem
              button
              onClick={handleLogout}
              sx={{ color: "#BA1A1A", borderRadius: "10px" }}
            >
              <ListItemIcon>
                <LogoutRounded sx={{ color: "#BA1A1A" }} />
              </ListItemIcon>
              <ListItemText primary="Cerrar sesión" />
            </ListItem>
          </FireNav>
        </Grid>
      </Grid>
    </Container>
  );

  return (
    <>
      <Drawer
        variant="persistent" // Cambiado a persistent para que el drawer no desaparezca por completo
        open={drawerOpen}
        sx={{
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: drawerWidth,
            borderRadius: "0 20px 20px 0",
          },
        }}
      >
        {drawerContent}
      </Drawer>
      <IconButton
        color="primary"
        aria-label="open drawer"
        edge="start"
        onClick={handleToggleDrawer}
        sx={{
          mr: 2,
          position: "fixed", // Posicionarlo fijo en la pantalla
          zIndex: 1301, // Asegurarse de que esté encima del drawer (los Drawers tienen un zIndex de 1200)
          top: "10px", // Ubicado en la parte superior
          left: drawerOpen ? `15px` : "20px", // Ajusta la posición dependiendo del estado del drawer
          transition: "left 0.3s",
        }}
      >
        <MenuIcon />
      </IconButton>
    </>
  );
};

export default MyDrawer;
