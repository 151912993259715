import React, {
  useEffect,
  useMemo,
  useState,
  createContext,
  useContext,
} from "react";
import { ThemeProvider, createTheme } from "@mui/material/styles";

// Crear el contexto para el tema
const ThemeToggleContext = createContext();

export const useThemeToggle = () => {
  return useContext(ThemeToggleContext);
};

const CustomThemeProvider = ({ children }) => {
  const [darkMode, setDarkMode] = useState(false);

  const theme = useMemo(() => {
    const primaryColor = darkMode ? "#FF4081" : "#4FC3F7"; // rosado fuerte vs celeste azulado
    const backgroundColor = darkMode ? "#121212" : "#e0e9f0"; // fondo oscuro vs fondo claro
    const textColor = darkMode ? "#FFFFFF" : "#000000"; // texto blanco vs negro
    const secondaryColor = darkMode ? "#575992" : "#FF4081"; // rosado fuerte vs celeste azulado
    const secondarytextColor = darkMode ? "#FFFFFF" : "#FFFFFF"; // texto blanco vs negro

    return createTheme({
      palette: {
        mode: darkMode ? "dark" : "light",
        primary: {
          main: primaryColor,
          contrastText: textColor,
        },
        secondary: {
          main: secondaryColor,
          contrastText: secondarytextColor,
        },
        background: {
          default: backgroundColor,
          paper: darkMode ? "#424242" : "#FFFFFF",
        },
      },
      typography: {
        fontFamily: "Nunito",
        displayLarge: {
          fontSize: "3.563rem",
          fontWeight: "400",
          lineHeight: "1.2",
          letterSpacing: "-0.01562em",
        },
        displayMedium: {
          fontSize: "2.813rem",
          fontWeight: "400",
          lineHeight: "1.2",
          letterSpacing: "-0.00833em",
        },
        displaySmall: {
          fontSize: "2.25rem",
          fontWeight: "400",
          lineHeight: "1.2",
          letterSpacing: "0em",
        },
        headlineLarge: {
          fontSize: "2rem",
          fontWeight: "400",
          lineHeight: "1.2",
          letterSpacing: "0em",
        },
        headlineMedium: {
          fontSize: "1.75rem",
          fontWeight: "400",
          lineHeight: "1.2",
          letterSpacing: "0em",
        },
        headlineSmall: {
          fontSize: "1.5rem",
          fontWeight: "400",
          lineHeight: "1.2",
          letterSpacing: "0em",
        },
        titleLarge: {
          fontSize: "1.375rem",
          fontWeight: "700",
          lineHeight: "1.2",
          letterSpacing: "0em",
        },
        titleMedium: {
          fontSize: "1.125rem",
          fontWeight: "700",
          lineHeight: "1.2",
          letterSpacing: "0.0075em",
        },
        titleSmall: {
          fontSize: "1rem",
          fontWeight: "700",
          lineHeight: "1.2",
          letterSpacing: "0.0075em",
        },
        labelLarge: {
          fontSize: "0.875rem",
          fontWeight: "700",
          lineHeight: "1.2",
          letterSpacing: "0.01938em",
        },
        labelMedium: {
          fontSize: "0.75rem",
          fontWeight: "700",
          lineHeight: "1.2",
          letterSpacing: "0.025em",
        },
        labelSmall: {
          fontSize: "0.625rem",
          fontWeight: "700",
          lineHeight: "1.2",
          letterSpacing: "0.03125em",
        },
        bodyLarge: {
          fontSize: "1rem",
          fontWeight: "400",
          lineHeight: "1.5",
          letterSpacing: "0.03125em",
        },
        bodyMedium: {
          fontSize: "0.875rem",
          fontWeight: "400",
          lineHeight: "1.5",
          letterSpacing: "0.01786em",
        },
        bodySmall: {
          fontSize: "0.75rem",
          fontWeight: "400",
          lineHeight: "1.5",
          letterSpacing: "0.03333em",
        },
      },
      shape: {
        borderRadius: 4,
      },
      spacing: 8,
      components: {
        MuiAppBar: {
          styleOverrides: {
            root: {
              backgroundColor: backgroundColor,
              color: "#424242",
            },
          },
        },
        MuiButton: {
          styleOverrides: {
            root: {
              borderRadius: "20px",
              textTransform: "none",
              padding: "10px 20px",
            },
            containedPrimary: {
              backgroundColor: primaryColor,
              "&:hover": {
                backgroundColor: darkMode ? "#D5006D" : "#14576a",
              },
            },
            outlinedSecondary: {
              borderColor: "#575992",
              color: "#575992",
              "&:hover": {
                borderColor: "#44466b",
                color: "#44466b",
              },
            },
          },
        },
        MuiCard: {
          styleOverrides: {
            root: {
              borderRadius: "20px",
              boxShadow: "0 4px 20px rgba(0,0,0,0.1)",
            },
          },
        },
        MuiTooltip: {
          styleOverrides: {
            tooltip: {
              fontSize: "0.875rem",
              backgroundColor: primaryColor,
            },
            arrow: {
              color: primaryColor,
            },
          },
        },
        MuiTab: {
          styleOverrides: {
            root: {
              "&.MuiTab-root": {
                fontSize: "0.75rem",
                fontWeight: "700",
                lineHeight: "1.2",
                letterSpacing: "0.025em",
              },
            },
          },
        },
        MuiDateCalendar: {
          styleOverrides: {
            root: {
              color: primaryColor,
              borderRadius: "8px",
              borderWidth: "1px",
              borderColor: `${primaryColor} !important`,
              border: "2px solid",
              "&.Mui-selected": {
                backgroundColor: primaryColor,
                color: "#83D5C4",
              },
              "&.Mui-focusVisible": {
                backgroundColor: primaryColor,
                color: "#83D5C4",
              },
              "&.Mui-selected.Mui-focusVisible": {
                backgroundColor: primaryColor,
                color: "#83D5C4",
              },
            },
          },
        },
        MuiPickersDay: {
          styleOverrides: {
            root: {
              "&.Mui-selected": {
                backgroundColor: primaryColor,
                color: "#83D5C4",
              },
              "&.Mui-focusVisible": {
                backgroundColor: primaryColor,
                color: "#83D5C4",
              },
              "&.Mui-selected.Mui-focusVisible": {
                backgroundColor: primaryColor,
                color: "#83D5C4",
              },
            },
          },
        },
      },
    });
  }, [darkMode]);

  useEffect(() => {
    const applySpecialCaseClass = () => {
      const formControls = document.querySelectorAll(".MuiFormControl-root");
      formControls.forEach((control) => {
        const label = control.querySelector("label");
        const input = control.querySelector(".MuiFilledInput-input");
        if (label && input) {
          input.classList.add("special-case");
        }
      });
    };

    applySpecialCaseClass();

    const observer = new MutationObserver(applySpecialCaseClass);
    observer.observe(document.body, {
      childList: true,
      subtree: true,
    });

    return () => observer.disconnect();
  }, []);

  return (
    <ThemeToggleContext.Provider value={() => setDarkMode((prev) => !prev)}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </ThemeToggleContext.Provider>
  );
};

export default CustomThemeProvider;
