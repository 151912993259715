import {
  addDoc,
  collection,
  doc,
  updateDoc,
  getDocs,
  onSnapshot,
  orderBy,
  query,
  serverTimestamp,
  where,
  deleteDoc,
  limit,
  setDoc,
} from "firebase/firestore";
import { db } from "./config";

function generarCodigo() {
  let caracteres = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
  let codigo = "";
  for (let i = 0; i < 6; i++) {
    codigo += caracteres.charAt(Math.floor(Math.random() * caracteres.length));
  }
  return codigo;
}

export async function createEvent(data) {
  try {
    let kd = generarCodigo();
    console.log("aa", kd);
    const docRef = doc(collection(db, "events"));
    await setDoc(docRef, {
      ...data,
      code: kd,
      createdAt: serverTimestamp(),
    });
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
}

export async function getEvents() {
  try {
    const queryData = query(collection(db, "events"));
    const doctors = await getDocs(queryData);
    return doctors.docs.map((doc) => {
      return {
        ...doc.data(),
      };
    });
  } catch (error) {
    console.log("Error al obtener eventos", error);
    return false;
  }
}
