import "./App.css";

import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import CreateEvent from "./pages/create_event.jsx";
import { Container } from "@mui/material";
import { lightBlue } from "@mui/material/colors";
import Login from "./pages/login";
import RecoverPass from "./pages/recover_pass";
import Home from "./pages/home.jsx";
import { useSelector } from "react-redux";
import { TitleProvider } from "./context/TitleContext";
import Layout from "./components/main_layout.jsx";
import NotFoundImage from "./assets/not-found.svg"; // Asegúrate de tener la imagen importada correctamente
import Typist from "./pages/typist.jsx";

function App() {
  const logged = useSelector((state) => state.auth.logged);
  const userData = useSelector((state) => state.auth.userData);
  let role = userData ? userData.role : null;

  return (
    <TitleProvider>
      <BrowserRouter>
        <Routes>
          {/* Rutas sin proteger */}
          <Route path="/login" element={<Login />} />
          <Route path="/recoverPass" element={<RecoverPass />} />
          <Route path="/home" element={<Home />} />
          <Route path="" element={<Home />} />
          <Route path="/typist" element={<Typist />} />

          {/* Rutas protegidas con Layout */}
          {role !== "admin" && (
            <Route element={<Layout title="App Layout" />}>
              <Route path="/inicio" element={<CreateEvent />} />
            </Route>
          )}

          <Route
            path="*"
            element={
              <Container style={{ marginLeft: "35%" }} fixed>
                <h1 style={{ color: lightBlue }}>No hay nada aquí...</h1>
                <img src={NotFoundImage} alt="not-found" width={"50%"} />
              </Container>
            }
          />
        </Routes>
      </BrowserRouter>
    </TitleProvider>
  );
}

export default App;

//Rutas que no se utilizan de momento
/* <Route path="/types" element={<Types />} /> */
/* <Route path="/upData" element={<UpData />} /> */
/* <Route
          path="/login"
          element={logged ? <Home /> : <Navigate to="/login" replace />}
        /> */
/*<Route
        path="/pendingRequest"
        element={logged  ? <PendingRequest /> : <Navigate to="/permissionDenied" replace />}
      />*/
