import React, { useEffect, useState } from "react";
import {
  Typography,
  Box,
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
  TableBody,
  TableContainer,
  Table,
  TextField,
} from "@mui/material";
import { Button } from "@mui/material";
import { useThemeToggle } from "../components/customThemeProvider";
import { useNavigate } from "react-router-dom";
import logo from "../assets/logo.png";

export default function Typist() {
  const navigate = useNavigate();
  const [selectedEventIndex, setSelectedEventIndex] = useState(null);
  const [events, setEvents] = useState([]);
  const [editableScores, setEditableScores] = useState({}); // Estado para puntajes editables
  const toggleTheme = useThemeToggle();

  const savedData = localStorage.getItem("EventToType");

  useEffect(() => {
    const loadEventData = async () => {
      if (savedData) {
        const eventData = JSON.parse(savedData);
        console.log("Datos cargados:", eventData);
        setEvents(eventData);
        setSelectedEventIndex(0);
      } else {
        console.error("No hay datos guardados.");
      }
    };
    loadEventData();
  }, [savedData]);

  // Si selectedEventIndex no es válido, retorna un mensaje o un componente vacío
  if (selectedEventIndex === null || !events[selectedEventIndex]) {
    return (
      <Typography variant="h6" color="error">
        No hay eventos disponibles o no se ha seleccionado un evento.
      </Typography>
    );
  }

  // Manejar cambios en los puntajes
  const handleScoreChange = (clubIndex, studentIndex, field, value) => {
    setEditableScores((prevScores) => ({
      ...prevScores,
      [`${clubIndex}-${studentIndex}`]: {
        ...prevScores[`${clubIndex}-${studentIndex}`],
        [field]: value,
      },
    }));
  };

  // Guardar puntajes editados
  const handleSave = (clubIndex, studentIndex) => {
    const updatedEvents = [...events];
    const student =
      updatedEvents[selectedEventIndex].clubs[clubIndex].students[studentIndex];

    // Actualiza el puntaje basado en editableScores
    const scoresToUpdate = editableScores[`${clubIndex}-${studentIndex}`];
    if (scoresToUpdate) {
      Object.keys(scoresToUpdate).forEach((field) => {
        if (scoresToUpdate[field] !== undefined) {
          student[field + "Score"] = parseFloat(scoresToUpdate[field]) || 0; // Asegura que sea un número
        }
      });
    }

    // Guarda los eventos actualizados en el estado y en localStorage
    setEvents(updatedEvents);
    console.log("actualizado", updatedEvents);
    localStorage.setItem("EventToType", JSON.stringify(updatedEvents));
  };

  const renderTable = (
    <Box
      sx={{
        overflow: "auto",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        width: "100%",
        height: "100%",
        marginTop: { xs: "30px" },
        padding: { lg: 10, md: 5, xs: 3 },
      }}
    >
      <Typography
        sx={{
          margin: 0,
          fontWeight: "700",
          fontSize: "40px",
        }}
      >
        {events[selectedEventIndex].title}
      </Typography>
      <img
        src={logo}
        alt="Logo"
        style={{
          height: 250,
          width: 250,
        }}
      />
      <TableContainer sx={{ overflow: "auto" }}>
        <Table sx={{ maxWidth: "100%" }} size={"medium"} align="center">
          <TableHead>
            <TableRow>
              {[
                { label: "Nombre", align: "left" },
                { label: "Club", align: "left" },
                { label: "Nivel", align: "left" },
                { label: "Franja", align: "left" },
                { label: "Salto", align: "right" },
                { label: "Barra", align: "right" },
                { label: "Viga", align: "right" },
                { label: "Suelo", align: "right" },
                { label: "Acumulado", align: "right" },
                { label: "Acciones", align: "right" },
              ].map(({ label, align }) => (
                <TableCell key={label} align={align}>
                  <TableSortLabel
                    sx={{
                      fontWeight: "700",
                      fontSize: "21px",
                    }}
                  >
                    {label}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {events[selectedEventIndex].clubs.map((club, clubIndex) =>
              club.students.map((student, studentIndex) => (
                <TableRow
                  key={`${clubIndex}-${studentIndex}`}
                  sx={{ height: "60px" }}
                >
                  <TableCell
                    align="left"
                    padding="none"
                    sx={{ fontSize: "17px" }}
                  >
                    {student.name}
                  </TableCell>
                  <TableCell
                    align="left"
                    padding="none"
                    sx={{ fontSize: "17px" }}
                  >
                    {club.clubName}
                  </TableCell>

                  <TableCell
                    align="left"
                    padding="none"
                    sx={{ fontSize: "17px" }}
                  >
                    {student.level}
                  </TableCell>
                  <TableCell
                    align="left"
                    padding="none"
                    sx={{ fontSize: "17px" }}
                  >
                    {student.timeSlot || "No asignado"}
                  </TableCell>
                  <TableCell
                    align="right"
                    padding="none"
                    sx={{ fontSize: "17px" }}
                  >
                    <TextField
                      fullWidth
                      defaultValue={student.saltoScore || ""}
                      onChange={(e) =>
                        handleScoreChange(
                          clubIndex,
                          studentIndex,
                          "salto",
                          e.target.value
                        )
                      }
                    />
                  </TableCell>
                  <TableCell
                    align="right"
                    padding="none"
                    sx={{ fontSize: "17px" }}
                  >
                    <TextField
                      fullWidth
                      defaultValue={student.barraScore || ""}
                      onChange={(e) =>
                        handleScoreChange(
                          clubIndex,
                          studentIndex,
                          "barra",
                          e.target.value
                        )
                      }
                    />
                  </TableCell>
                  <TableCell
                    align="right"
                    padding="none"
                    sx={{ fontSize: "17px" }}
                  >
                    <TextField
                      fullWidth
                      defaultValue={student.vigaScore || ""}
                      onChange={(e) =>
                        handleScoreChange(
                          clubIndex,
                          studentIndex,
                          "viga",
                          e.target.value
                        )
                      }
                    />
                  </TableCell>
                  <TableCell
                    align="right"
                    padding="none"
                    sx={{ fontSize: "17px" }}
                  >
                    <TextField
                      fullWidth
                      defaultValue={student.sueloScore || ""}
                      onChange={(e) =>
                        handleScoreChange(
                          clubIndex,
                          studentIndex,
                          "suelo",
                          e.target.value
                        )
                      }
                    />
                  </TableCell>
                  <TableCell
                    align="right"
                    padding="none"
                    sx={{ fontSize: "15px", padding:2 }}
                  >
                    {[
                      student.saltoScore,
                      student.barraScore,
                      student.vigaScore,
                      student.sueloScore,
                    ].reduce((acc, score) => acc + (parseFloat(score) || 0), 0)}
                  </TableCell>
                  <TableCell
                    align="right"
                    padding="none"
                    sx={{ display: "flex" }}
                  >
                    <Button
                      variant="contained"
                      color="secondary"
                      sx={{
                        mx: "5px",
                        minWidth: { md: "45%", xs: "100%" },
                      }}
                      onClick={() => handleSave(clubIndex, studentIndex)} // Almacena los cambios
                    >
                      Guardar
                    </Button>

                    <Button
                      variant="contained"
                      color="primary"
                      sx={{
                        minWidth: { md: "45%", xs: "100%" },
                      }}
                      onClick={() =>
                        console.log("Editar función no implementada")
                      } // Placeholder para editar
                    >
                      Editar
                    </Button>
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );

  return (
    <Box
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Button
        onClick={toggleTheme}
        variant="contained"
        color="secondary"
        sx={{
          position: "absolute",
          top: 16,
          right: 150,
          zIndex: 1,
        }}
      >
        Cambiar Tema
      </Button>
      <Button
        onClick={() => navigate("/")}
        variant="contained"
        color="primary"
        sx={{
          position: "absolute",
          top: 16,
          right: 16,
          zIndex: 1,
        }}
      >
        Cerrar sesión
      </Button>
      {renderTable}
    </Box>
  );
}
